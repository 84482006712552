import './Certificate.scss'
import {useTranslation} from "react-i18next";
import Certificate_1 from '../../assets/images/certificate1.jpg';
import Certificate_2 from '../../assets/images/certificate2.jpg';
import Certificate_3 from '../../assets/images/certificate3.png';
import Certificate_4 from '../../assets/images/certificate4.png';
import Certificate_5 from '../../assets/images/certificate5.png';
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";





export const Certificate = () => {
    const {t} = useTranslation()
    const titleRef = useRef<HTMLHeadingElement>(null)
    const isVisibleTitle = useOnScreen(titleRef)

    return <section className={`certificate-sec G-animation ${isVisibleTitle ? 'G-show-element' : ''}`} ref={titleRef}>
        <div className="G-container">
            <h2>{t("certificate-title")}</h2>
            <div className="certificate-cnt G-flex G-align-center G-justify-center G-flex-wrap">
                <div className="certificate-photo">
                    <img src={Certificate_1} alt="certificate"/>
                </div>
                <div className="certificate-photo">
                    <img src={Certificate_2} alt="certificate"/>
                </div>

                <div className="certificate-photo">
                    <img src={Certificate_4} alt="certificate"/>
                </div>
                <div className="certificate-photo">
                    <img src={Certificate_5} alt="certificate"/>
                </div>
                <div className="certificate-photo">
                    <img src={Certificate_3} alt="certificate"/>
                </div>
            </div>
        </div>
    </section>
}
