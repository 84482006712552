import './CardWork.scss'
import {ICardWork} from "../../models/works.model";
import {useTranslation} from "react-i18next";


interface Props extends ICardWork {
    onClick: () => void
}

export const CardWork = ({image, title, onClick}: Props) => {
    const {t} = useTranslation()
    return <div className="about-works-item" onClick={onClick}>
        <div className="about-works-photo">
            <img src={image} alt="image"/>
        </div>
        <h3>{t(title)}</h3>
        <p>{t('reed-more')}</p>
    </div>
}





