import './Partners.scss'
import {useTranslation} from "react-i18next";
import Partner_1 from '../../assets/images/partner-1.png';
import Partner_2 from '../../assets/images/partner-2.png';
import Partner_3 from '../../assets/images/partner-3.png';
import Partner_4 from '../../assets/images/partner-4.png';
import Partner_5 from '../../assets/images/partner-5.png';
import Partner_6 from '../../assets/images/partner-6.png';
import Partner_7 from '../../assets/images/partner-7.png';
import Partner_8 from '../../assets/images/partner-8.png';
import Partner_9 from '../../assets/images/partner-9.png';

import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import {Autoplay, FreeMode} from "swiper/modules";

export const Partners = () => {
    const {t} = useTranslation()

    const settings = {
        loop: true,
        spaceBetween: 100,
        slidesPerView: 6,
        autoplay: {
            delay: 1,
            disableOnInteraction: false,
        },
        speed: 2000,
        breakpoints: {
            320: {
                spaceBetween: 50,
                slidesPerView: 2

            },
            768: {
                spaceBetween: 12,
                slidesPerView: 3

            },
            1024: {
                spaceBetween: 20,
                slidesPerView: 3

            },
            1300: {
                spaceBetween: 20,
                slidesPerView: 6
            },
        },
    }


    return <section className="partners-sec">
        <div className="G-container">
            <h2>{t('partners-title')}</h2>
        </div>
        <Swiper {...settings} modules={[Autoplay, FreeMode]} navigation={false}
                className="partners-slider swiper-container-free-mode">
            <SwiperSlide>
                <div className="partners-logo">
                    <img src={Partner_1} alt="partners"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="partners-logo">
                    <img src={Partner_2} alt="partners"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="partners-logo">
                    <img src={Partner_3} alt="partners"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="partners-logo">
                    <img src={Partner_4} alt="partners"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="partners-logo">
                    <img src={Partner_5} alt="partners"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="partners-logo">
                    <img src={Partner_6} alt="partners"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="partners-logo">
                    <img src={Partner_7} alt="partners"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="partners-logo">
                    <img src={Partner_8} alt="partners"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="partners-logo">
                    <img src={Partner_9} alt="partners"/>
                </div>
            </SwiperSlide>
        </Swiper>
    </section>
}
