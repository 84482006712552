import './Banner.scss'
import Bannervideo from '../../assets/video/shin.mp4';
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";

export const Banner = () => {

    const {t} = useTranslation()
    const titleRef = useRef<HTMLHeadingElement>(null)
    const isVisibleTitle = useOnScreen(titleRef)

    return <section className="banner-sec">
        <div className="separation-video">
            <video autoPlay loop muted>
                <source src={Bannervideo} type="video/mp4"/>
            </video>
        </div>
        <div className="G-container">
            <div className="banner-cnt">
                <h1 ref={titleRef}
                    className={`G-animation ${isVisibleTitle ? 'G-show-element' : ''}`}>{t('banner-title')}</h1>
                <p ref={titleRef}
                   className={`G-animation ${isVisibleTitle ? 'G-show-element' : ''}`}>{t('banner-text')}</p>
            </div>
        </div>
    </section>
}
