import './Works.scss'
import {useTranslation} from "react-i18next";
import WorkPhoto from '../../assets/images/work-photo.png';
import WorkPhoto2 from '../../assets/images/work-2.jpg';
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";

export const Works = () => {
    const {t} = useTranslation()
    const titleRef = useRef<HTMLHeadingElement>(null)
    const isVisibleTitle = useOnScreen(titleRef)

    return <section className={`work-sec G-animation ${isVisibleTitle ? 'G-show-element' : ''}`} id='services' ref={titleRef}>
        <div className="G-container">
            <h2>{t('work-title')}</h2>
            <div className="work-cnt G-flex G-justify-between G-align-center">
                <div className="work-info">
                    <h2>{t('workTitle_1')}</h2>
                    <p>{t('workText_1')}</p>
                </div>
                <div className="work-photo">
                    <img src={WorkPhoto} alt="worker"/>
                </div>
            </div>
            <div className="work-cnt G-flex G-justify-between G-align-center">
                <div className="work-photo">
                    <img src={WorkPhoto2} alt="worker"/>
                </div>
                <div className="work-info">
                    <h2>{t('workTitle_2')}</h2>
                    <p>{t('workText_2')}</p>
                </div>

            </div>
        </div>
    </section>
}
