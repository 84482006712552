import {useTranslation} from "react-i18next";
import {LanguageEnum} from "../enums/language.enum";
import {useEffect} from "react";
import useLocalStorage from "./useLocalStorage";


const useLanguage = () => {
    const {i18n} = useTranslation()
    const [language, setLanguage] = useLocalStorage('language', '')
    useEffect(() => {

        if (!language) {
            setLanguage(LanguageEnum[LanguageEnum.armenian])
        } else {
            setLanguage(language)
            i18n.changeLanguage(language)
        }
    }, [])

    const changeLanguage = (data: string) => {
        i18n.changeLanguage(data)
        setLanguage(data)
    }

    return {changeLanguage, language}

}
export default useLanguage


