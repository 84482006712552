import './Gallery.scss';
import {useTranslation} from "react-i18next";
import Gallery_1 from '../../assets/images/gallery-1.png';
import Gallery_2 from '../../assets/images/gallery-2.png';
import Gallery_3 from '../../assets/images/gallery-3.png';
import Gallery_4 from '../../assets/images/gallery-4.png';
import Gallery_5 from '../../assets/images/gallery-5.png';
import Gallery_6 from '../../assets/images/gallery-6.png';
import Gallery_7 from '../../assets/images/gallery-7.png';
import Gallery_8 from '../../assets/images/gallery-8.png';
import Gallery_9 from '../../assets/images/gallery-9.png';
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";

export const Gallery = () => {
    const {t} = useTranslation()
    const titleRef = useRef<HTMLHeadingElement>(null)
    const isVisibleTitle = useOnScreen(titleRef)

    return <section className={`gallery-sec G-animation ${isVisibleTitle ? 'G-show-element' : ''}`} ref={titleRef}
                    id='gallery'>
        <div className="G-container">
            <h2>{t('gallery-title')}</h2>
            <div className="gallery-cnt G-flex G-flex-wrap">
                <div className="gallery-photo">
                    <img src={Gallery_1} alt="gallery"/>
                </div>
                <div className="gallery-photo">
                    <img src={Gallery_2} alt="gallery"/>
                </div>
                <div className="gallery-photo">
                    <img src={Gallery_3} alt="gallery"/>
                </div>
                <div className="gallery-photo">
                    <img src={Gallery_4} alt="gallery"/>
                </div>
                <div className="gallery-photo">
                    <img src={Gallery_5} alt="gallery"/>
                </div>
                <div className="gallery-photo">
                    <img src={Gallery_6} alt="gallery"/>
                </div>
                <div className="gallery-photo">
                    <img src={Gallery_7} alt="gallery"/>
                </div>
                <div className="gallery-photo">
                    <img src={Gallery_8} alt="gallery"/>
                </div>
                <div className="gallery-photo">
                    <img src={Gallery_9} alt="gallery"/>
                </div>
            </div>
        </div>
    </section>
}
