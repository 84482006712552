import './Footer.scss'
import LogoImage from '../../assets/images/ShinControl_white_en.svg'
import {IconWhatsapp} from "../../assets/icons/IconWhatsapp";
import {IconViber} from "../../assets/icons/IconViber";



export const Footer = () => {
    return <footer>
        <div className="G-container">
            <div className="footer-main G-flex G-align-center G-justify-between">
                <div className="footer-logo G-flex">
                    <img src={LogoImage} alt="logo"/>
                </div>
                <div className="footer-phone ">
                    <ul className="G-flex G-align-center footer-links">
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=099909323">
                                <IconWhatsapp size={30}/>
                            </a>
                        </li>
                        <li>
                            <a  href="https://vb.me/099909323">
                                <IconViber size={30}/>
                            </a>
                        </li>
                    </ul>
                    <a href="tel:+37494300728">+374 094 300728</a>
                    <a href="tel:+37499364333">+374 099 364333</a>
                    <a href="mailto:shincontrol.llc@gmail.com">shincontrol.llc@gmail.com</a>
                </div>
            </div>
        </div>
    </footer>
}
